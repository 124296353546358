:root {
  --prompt-width: min(700px, calc(62vw - 96px));
  --aside-offset: calc(var(--prompt-width) + 32px);
  --default-font: "Inter", sans-serif;
  --bg-color: #f0f0f0;
  --default-text-color: #222;
  --border-color: #ccc;
  --selected-border-color: rgb(237, 211, 137);
  --selected-bg-color: rgb(253 235 184);
  --selected-text-color: #222;
  --selected-tool-bg-color: rgb(0 0 0 / 8%);
  --selected-tool-response-tool-bg-color: rgb(0 0 0 / 8%);
  --response-bg-color: white;
  --tool-text-color: #007fff;
  --tool-bg-color: rgb(0 127 255 / 10%);
  --tool-selected-bg-color: rgb(82 162 244);
  --tool-border-color: #b4d9ff;
  --tool-response-bg-color: rgb(242 249 255);
  --tool-response-text-color: #222;
  --tool-response-selected-text-color: #222;
  --tool-response-selected-bg-color: #d8edff;
  --tool-response-selected-border-color: #a0c1e3;
  --special-token-color: #777;
  --prompt-font: "Roboto Mono", monospace;
  --prompt-text-color: #666;
  --prompt-border-color: #ccc;
  --prompt-tool-bg-color: rgb(0 0 0 / 8%);
  --button-bg-color: #e3e3e3;
  --button-text-color: #6e7071;
  --button-hover-bg-color: #a6a6ab;
  --button-hover-text-color: white;
  --button-pulse: #bdbdc4;
  font-family: var(--default-font);
  --default-font-weight: 500;
  font-weight: var(--default-font-weight);
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #2f3237;
    --default-text-color: #eee;
    --border-color: #595b60;
    --selected-text-color: #ffde98;
    --selected-bg-color: rgb(73 69 61);
    --selected-border-color: rgb(109 102 81);
    --response-bg-color: #292b2f;
    --tool-text-color: #7dbdff;
    --tool-selected-bg-color: rgb(47 98 161);
    --tool-response-bg-color: #292b2f;
    --tool-response-text-color: #b3d7f8;
    --tool-border-color: #4a5f79;
    --prompt-text-color: #bbb;
    --prompt-border-color: #595b60;
    --prompt-tool-bg-color: rgb(255 255 255 / 8%);
    --tool-text-color: #78bbff;
    --tool-bg-color: rgb(84 169 255 / 20%);
    --tool-response-selected-text-color: #bcdefe;
    --tool-response-selected-bg-color: rgb(60 108 194 / 24%);
    --tool-response-selected-border-color: #4a5f79;
    --selected-tool-bg-color: rgb(255 175 42 / 15%);
    --selected-tool-response-tool-bg-color: rgb(129 176 255 / 16%);
    --button-bg-color: rgba(255 255 255 / 7%);
    --button-text-color: #bbbec9;
    --button-hover-bg-color: rgba(255 255 255 / 30%);
    --button-hover-text-color: white;
    --button-pulse: rgba(255 255 255 / 30%);
    --default-font-weight: 400;
  }
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: var(--default-font);
  }
}

body {
  background-color: var(--bg-color);
  color: var(--default-text-color);
  margin: 32px;
  /* font-weight: 400; */
}

.aside {
  width: max(300px, calc(38vw));
  max-width: 500px;
  position: fixed;
  top: 0;
  left: var(--aside-offset);
  margin: 32px 0 32px 32px;
}

.aside h1 {
  margin: 0 0 16px 0;
  line-height: 1.1em;
}

.aside .results {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 16px;
  margin-bottom: 16px;
}

.aside .results tr:hover td {
  background-color: var(--selected-tool-bg-color);
}

.aside .results th,
.aside .results td {
  font-size: 11pt;
  color: var(--selected-text-color);
}

.aside .results thead th {
  border-top: 2px solid var(--selected-text-color);
  border-bottom: 1px solid var(--selected-text-color);
  text-align: left;
}

.aside .results tr:last-child td {
  border-bottom: 2px solid var(--selected-text-color);
}

.aside .results .numerical {
  text-align: right;
}

.trajectory {
  width: var(--prompt-width);
  margin-bottom: 100px;
}

h1 {
  font-weight: calc(var(--default-font-weight) + 100);
  font-size: 20pt;
  margin-bottom: 0;
}

.visible {
  display: block !important;
}

.selectable:hover {
  cursor: pointer;
}

.selectable.selected:hover {
  cursor: default;
}

/* Get rid of focus outline on keyboard shortcuts */
div[tabindex="-1"]:focus {
  outline: 0;
}

/* FIXME: remove this once it is added to the component library */
.aics-collapsible-block-inner > .aics-named-block {
  margin-top: 4px;
}

/* Code blocks */

.ͼo {
  background-color: transparent;
}

.geirpy .aics-code-section {
  background-color: rgb(0, 0, 0, 0.07);
  .ͼp,
  .ͼq {
    font-weight: bold;
  }
  .ͼo {
    background-color: transparent;
    color: #666;
  }
  .ͼ13 {
    font-style: italic;
  }
  .ͼ13,
  .ͼp,
  .ͼq,
  .ͼr,
  .ͼu,
  .ͼv,
  .ͼw,
  .ͼy {
    color: #666;
  }
}
