:root {
  --diagram-width: 110px;
  --diagram-prompt-bg: white;
  --diagram-prompt_color: #666;
  --diagram-arrow_color: #666;
  --diagram-model_color: #1fc264;
  --diagram-model_bg: #d2ecdd;
  --diagram-action_color: #b7dfff;
  --diagram-tool_color: #0089f6;
  --diagram-tool_bg: #e0f1ff;
  --diagram-examples_color: #ddd;
}

@media (prefers-color-scheme: dark) {
  :root {
    --diagram-prompt-bg: #27282c;
    --diagram-prompt_color: #ccc;
    --diagram-arrow_color: #ccc;
    --diagram-model_color: #3c9d65;
    --diagram-model_bg: #304e3d;
    --diagram-action_color: #306087;
    --diagram-tool_color: #1e84d5;
    --diagram-tool_bg: #293a48;
    --diagram-examples_color: #646974;
  }
}

body {
  margin: 48px 72px 0;
}

.Home h1 {
  font-weight: 500;
  font-size: 20pt;
  margin-bottom: 16px;
}

.Home p {
  margin: 0 0 48px 0;
}

#container {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  grid-auto-flow: row;
  column-gap: 32px;
  row-gap: 24px;
}

@media screen and (max-width: 800px) {
  #container {
    grid-template: repeat(1, 1fr) / repeat(1, 1fr);
  }
}

.card {
  max-width: 500px;
  min-width: 300px;
  position: relative;
  min-height: 200px;
}

figure {
  position: absolute;
  margin: 0;
}

.card h2 {
  margin: 0 0 8px var(--diagram-width);
  font-weight: 500;
  font-size: 16pt;
}

.card a {
  text-decoration: none;
  color: var(--text-color);
}

.card a:hover {
  text-decoration: underline;
}

.card .citation {
  margin-left: var(--diagram-width);
  color: var(--citation-text-color);
  font-size: 10pt;
}

figure .prompt {
  stroke: var(--diagram-prompt_color);
  fill: var(--diagram-prompt-bg);
}

figure .arrow {
  stroke: var(--diagram-arrow_color);
}

figure .model {
  stroke: var(--diagram-model_color);
  fill: var(--diagram-model_bg);
}

figure .tool {
  stroke: var(--diagram-tool_color);
  fill: var(--diagram-tool_bg);
}

figure .action {
  stroke: none;
  fill: var(--diagram-action_color);
}

figure .examples {
  stroke: none;
  fill: var(--diagram-examples_color);
}

figure .tools {
  stroke: none;
  fill: var(--diagram-action_color);
}
