:root {
  --default-font: "Inter", sans-serif;
  --bg-color: #f0f0f0;
  --text-color: #222;
  --citation-text-color: #666;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #2f3237;
    --text-color: #eee;
    --citation-text-color: #888;
  }
}

body {
  background-color: var(--bg-color);
  font-family: "Inter", sans-serif;
  font-family: var(--default-font);
  color: var(--text-color);
  max-width: 1200px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumbs {
  color: var(--citation-text-color);
  font-size: 11pt;

  margin-bottom: 8px;
}

.breadcrumbs a {
  font-weight: 600;
  color: var(--citation-text-color);
  text-decoration: none;
}

.breadcrumbs a:hover {
  color: var(--text-color);
  text-decoration: underline;
}

.breadcrumbs .material-icons {
  font-size: 18px;
  line-height: 18px;
  vertical-align: bottom;
}

footer {
  text-align: right;
  font-size: 10pt;
  color: var(--citation-text-color);
  margin-top: 16px;
}

footer a {
  color: var(--text-color);
}
